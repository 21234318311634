<template>
	<main 
		class="home-page"	
		@mousemove="methods.motionDetected"
		@keypress="methods.motionDetected"
		@scroll="methods.motionDetected"
		@click="methods.motionDetected"
	>
		<section :class="'hero-section slide-1 full-container' + (state.currentSlide == 1 ? ' active' : '')">
			<div class="hero-image slide-1">
				<div class="side-fade" />
			</div>
			
			<div class="details slide-1">
				<span class="header-text">
					<span class="highlight">Welcome to Neobanx.</span>
					Where we provide a safe, convenient way to facilitate loans between friends and relatives.
				</span>
				
				<div class="nb-vspacer" />

				<div class="tag">
					<q-icon class="icon" name="emoji_objects" />
					How It Works
				</div>
				
				<div class="nb-vspacer" />
				
				<span class="detail-text emphasize">
					Neobanx enables you to connect with people you know for lending and borrowing money. 
					We make lending between Funding Direct safe, professional, and mutually beneficial.
				</span>

				<div class="nb-vspacer" />

				<span class="detail-text">
					Our service handles the loan administration and legal documentation, automates payment transfers, 
					and even provides access to insurance to cover your payments if something goes wrong.
				</span>

				<div class="nb-vspacer" />

				<a class="flat-button" @click="$router.push('/about-neobanx')">
					Learn more about Neobanx

					<q-icon class="icon" name="arrow_forward" />
				</a>
			</div>
		</section>

		<section :class="'hero-section slide-2 full-container' + (state.currentSlide == 2 ? ' active' : '')">
			<div class="hero-image slide-2">
				<div class="side-fade" />
			</div>
			
			<div class="details slide-2">
				<span class="header-text">
					<span class="highlight">Help out your friends and relatives.</span>
					Our safe, secure environment makes it easy to lend money to people you know.
				</span>
				
				<div class="nb-vspacer" />

				<div class="tag">
					<q-icon class="icon" name="emoji_objects" />
					Lend To Friends And Family
				</div>
				
				<div class="nb-vspacer" />
				
				<span class="detail-text emphasize">
					Want to help out someone you know with a loan?
				</span>

				<div class="nb-vspacer" />

				<span class="detail-text">
					Use Neobanx to send a loan proposal to whomever you'd like to help. 
					Our loan tools make it easy for the two of you to negotiate terms, formalize the agreement and track repayments.
				</span>

				<div class="nb-vspacer" />

				<a class="flat-button" @click="$router.push('/lending')">
					Learn more about Lending

					<q-icon class="icon" name="arrow_forward" />
				</a>

				<div class="nb-vspacer xxlarge" />

				<a class="button" @click="$router.push('/lending?section=start-now')">
					Start Lending Now
				</a>
			</div>
		</section>
	
		<section :class="'hero-section slide-3 full-container' + (state.currentSlide == 3 ? ' active' : '')">
			<div class="hero-image slide-3">
				<div class="side-fade" />
			</div>
			
			<div class="details slide-3">
				<span class="header-text">
					<span class="highlight">Skip the banks.</span>
					Want to borrow from someone you know? Let us do the asking. We'll even handle all the details.
				</span>
				
				<div class="nb-vspacer" />

				<div class="tag">
					<q-icon class="icon" name="emoji_objects" />
					Borrow From Friends And Family
				</div>
				
				<div class="nb-vspacer" />
				
				<span class="detail-text emphasize">
					Need money to start a business, pay for school, fix a car or pay off a credit card?
				</span>

				<div class="nb-vspacer" />

				<span class="detail-text">
					Use Neobanx to create your loan proposal and send it to a potential lender you know. 
					Then you can negotiate until you both agree on terms.
				</span>

				<div class="nb-vspacer" />

				<a class="flat-button" @click="$router.push('/borrowing')">
					Learn more about Borrowing

					<q-icon class="icon" name="arrow_forward" />
				</a>

				<div class="nb-vspacer xxlarge" />

				<a class="button" @click="$router.push('/borrowing?section=start-now')">
					Start Borrowing Now
				</a>
			</div>
		</section>

		<section 
			:class="'slide-nav ' + (state.slideNavActive ? 'active' : '')" 
			@mouseenter="state.slideNavActive = true"
			@mouseleave="state.slideNavActive = false"
		>
			<div class="buttons-container">
				<a :class="'button ' + (state.currentSlide == 1 ? 'active' : '')" @click="state.currentSlide = 1">
					How It Works

					<div class="indicator" v-show="state.currentSlide == 1" />
				</a>

				<a :class="'button ' + (state.currentSlide == 2 ? 'active' : '')" @click="state.currentSlide = 2">
					Lending

					<div class="indicator" v-show="state.currentSlide == 2" />
				</a>
				
				<a :class="'button ' + (state.currentSlide == 3 ? 'active' : '')" @click="state.currentSlide = 3">
					Borrowing

					<div class="indicator" v-show="state.currentSlide == 3" />
				</a>
			</div>
		</section>
	</main>
</template>

<script>
import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
	name: 'HomePage',

	setup() {
		const state = reactive({
			currentSlide: 1,
			idleIntervalId: null,
			slideNavActive: true
		})

		const methods = {
			startIdleInterval: () => {
				state.idleIntervalId = setInterval(() => {
					if (state.currentSlide == 3)
						state.currentSlide = 1
					else 
						state.currentSlide += 1
				}, 10000)
			},

			motionDetected: () => {
				clearInterval(state.idleIntervalId)
				methods.startIdleInterval()
			}
		}

		setTimeout(() => {
			state.slideNavActive = false;
		}, 6000);

		methods.startIdleInterval()

		return { state, methods }
	}
})
</script>


<style lang="scss" scoped>
.home-page {
	display: grid;
	width: 100%;
	height: 100%;
	position: relative;

	.hero-section {
		grid-row: 1;
		grid-column: 1;
		z-index: 1;
		opacity: 0;
		transition: opacity 1s;

		.hero-image {
			display: flex;
			justify-content: flex-end;
			flex: 1;
			height: 100%;
			background-repeat: no-repeat;
			background-position: center;
			background-size: cover;

			.side-fade {
				display: flex;
				width: 10%;
				height: 100%;
				//border: 1px solid #f00;
			}
		}

		.details {
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 35%;
			height: 100%;
			background-color: #E4DFD9;
			//border: 1px solid #f00;
			padding: 40px;

			.header-text {
				color: #5761A7;
				font-size: 25px;
				font-family: MartelRegular;
				font-weight: bold;

				.highlight {
					color: #EA7914;
				}
			}

			.tag {
				display: flex;
				align-items: center;
				width: fit-content;
				text-transform: uppercase;
				font-weight: bolder;
				background-color: #7E89AD;
				font-size: 12px;
				padding: 2px 6px;
				border-radius: 4px;
				color: rgba(#fff, 0.6);

				.icon {
					font-size: 16px;
					margin-right: 4px;
					color: rgba(#fff, 0.8);
				}
			}

			.detail-text {
				font-size: 17px;
				color: rgba(#000, 0.7);
			}

			.detail-text.emphasize {
				font-weight: 500;
			}

			.flat-button {
				display: flex;
				align-items: center;
				text-transform: uppercase;
				font-weight: bolder;
				font-size: 13px;
				color: #5761A7;
				cursor: pointer;

				.icon {
					color: #EA7916;
					font-weight: bold;
					font-size: 16px;
					margin-left: 4px;
				}
			}

			.button {
				display: flex;
				justify-content: center;
				align-items: center;
				width: fit-content;
				height: 36px;
				color: rgba(#fff, 0.9);
				border-radius: 4px;
				font-weight: bolder;
				font-size: 14px;
				text-transform: uppercase;
				padding: 0 16px;
				background: linear-gradient(135deg, #EA7916 0, #D16C13 100%);
				cursor: pointer;
			}
		}
	}

	.hero-section.active {
		opacity: 1;
		z-index: 5;
	}

	.hero-section.slide-1 {
		.hero-image.slide-1 {
			background-image: url(/images/family_001.jpg);

			.side-fade {
				background: linear-gradient(90deg, rgba(#E4DFD9, 0) 0, #E4DFD9 100%);
			}
		}

		.details.slide-1 {
			background-color: #E4DFD9;
		}
	}

	.hero-section.slide-2 {
		.hero-image.slide-2 {
			background-image: url(/images/friends_001.jpg);

			.side-fade {
				background: linear-gradient(90deg, rgba(#E4E8E9, 0) 0, #E4E8E9 100%);
			}
		}

		.details.slide-2 {
			background-color: #E4E8E9;
		}
	}

	.hero-section.slide-3 {
		.hero-image.slide-3 {
			background-image: url(/images/friends_002.jpg);

			.side-fade {
				background: linear-gradient(90deg, rgba(#fff, 0) 0, #fff 100%);
			}
		}

		.details.slide-3 {
			background-color: #fff;
		}
	}

	.slide-nav {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: fit-content;
		position: absolute;
		left: 0;
		bottom: 0;
		//border: 1px solid #f00;
		padding: 4px;
		z-index: 5;

		.buttons-container {
			display: flex;
			width: fit-content;
			height: fit-content;
			padding: 4px;
			//border: 1px solid #f00;

			.button {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 18px;
				height: 18px;
				border-radius: 50%;
				background-color: rgba(#5761A7, 0.8);
				box-shadow: inset 1px 1px 2px rgba(#000, 0.2);
				cursor: pointer;
				overflow: hidden;
				text-align: center;
				position: relative;
				font-size: 12px;
				text-transform: uppercase;
				font-weight: bolder;
				color: rgba(#fff, 0);
				white-space: nowrap;
				transition: width 1s, border-radius 1s, color 1s;
				
				.indicator {
					display: flex;
					width: 12px;
					height: 12px;
					border-radius: 50%;
					background-color: #FF9800;
					box-shadow: 1px 1px 2px rgba(#000, 0.5);
					position: absolute;
					margin: auto;
					transition: opacity 0.5s;
				}
			}

			.button:hover {
				filter: brightness(110%);
			}

			.button:nth-child(n + 2) {
				margin-left: 16px;
			}

			.button.active {
				color: transparent;
			}
		}
	}

	.slide-nav.active {
		.button {
			width: 120px;
			border-radius: 8px;
			color: rgba(#fff, 0.7);

			.indicator {
				opacity: 0;
			}
		}

		.button.active {
			color: #FF9800;
		}
	}
}
</style>